import React from 'react';
/* global google */
import MasterForm from "./Form/MasterForm";
import {ErrorMessage, Field} from "formik";
import ReactGA from 'react-ga'
import API from "../../_helpers/API";
import CircularProgress from "@material-ui/core/CircularProgress";
import queryString from 'query-string'
import {Spring, Transition, animated} from 'react-spring/renderprops'
import Input from "@material-ui/core/Input";
import PropTypes from 'prop-types';

import SendIcon from '../../assets/svg/paper-plane-regular.svg';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
const required = value => (value ? undefined : 'Required');


function addItem(state) {
    const items = [...state.items]


    const previous = state.items[state.items.length - 1]
    const left = Math.round(Math.random()) === 1
    const first = previous === undefined || previous.left !== left

    const text = this.texts[count];
    if (previous !== undefined) previous.last = first
    items.push({key: state.items.length, text, left, first, last: true})

    return {items}
}

let count = 0;

const addressDetails = {
    street_number: "short_name",
    route: "long_name",
    locality: "long_name",
    administrative_area_level_1: "short_name",
    country: "long_name",
    postal_code: "short_name"
};


class RegisterPage extends React.Component {
    constructor(props) {
        super(props);
        this.API = new API();
        this.textInput = null;
        this.registerFormRef = React.createRef();



        this.autocompleteInput = React.createRef();
        this.autocomplete = null;

        this.texts = [
            "Hi, ich bin Alexandra und möchte etwas mehr von dir erfahren 😊. Aus welchem Land kommst du?",
            "Wähle einfach eines der Länder aus!"
        ];

        this.state = {
            camp: '',
            cid: '',
            email: '',
            submitted: false,
            success: false,
            isValidating: false,
            items: [],
            countries: false,
            zipcode: false,
        };


        this.toggle = (element) => {
            this.setState(state => ({ [element]: true }))
        };

        ReactGA.pageview(window.location.pathname + window.location.search);
    }


    list = React.createRef();
    el = React.createRef();
    endOfList = React.createRef();

    scrollToBottom = () =>
        this.list.current.scrollTop = this.list.current.scrollHeight;

    componentDidMount() {
        //Sentence
        setTimeout(() => {
            this.setState(addItem);
            count++;

        }, 1000);
        //Sentence
        setTimeout(() => {
            this.setState(addItem);
            count++;
        }, 1500);
        //Show countries
        setTimeout(() => {
            this.toggle('countries')
        }, 0);


    }

    _renderSuccess() {

        return (
            <div className="card bg-dark mb-3" style={{width: '90%'}}>
                <div className="card-body" style={{textAlign: 'center'}}>
                    <h5 className="card-title">Herzlichen Glückwunsch du hast es geschafft!</h5>
                    <p className="card-text">
                        Wir senden dir nun einen <b>Aktivierungslink</b> an deine Email Adresse.
                        <br/><br/>
                        Email: <b>{this.state.email}</b>
                        <br/><br/>
                        Nachdem du auf diesen Link geklickt hast ist dein Account sofort einsatzbereit!
                        <br/>
                        Wir wünschen dir viel Erfolg bei der Suche nach deiner Traumfrau

                    </p>
                </div>
            </div>
        )
    }

    _renderForm() {

        var {isValidating} = this.state;

        const {zipcode, countries } = this.state;



        return (
            <MasterForm
                cid={this.state.cid}
                location={this.props.location}
                ref={this.registerFormRef}
                onSubmit={(values, actions) => {
                    this.API.doRegister(values).then((data) => {
                        actions.setSubmitting(false);

                        if (data.status === true || data.status === 'true') {
                            this.setState({
                                success: true,
                                isValidating: false
                            })
                        }

                    });
                }}
            >

                <MasterForm.Page
                    validate={(values) => {

                        var country = '';
                        switch(values.country) {
                            case "105": {
                                country = 'Deutschland';
                            };
                            break;
                            case "106": {
                                country = 'Österreich';
                            };
                                break;
                            case "107": {
                                country = 'Schweiz';
                            };
                                break;
                        }
                        this.texts.push(`Aus ${country} also 👍🏻 Ok wie ist denn deine Postleitzahl? Bin gespannt ob du aus meiner Nähe kommst ❣️`);
                        setTimeout(() => {
                            this.setState(addItem);
                            count++;
                            this.scrollToBottom();
                        }, 400);

                        //Show countries
                        setTimeout(() => {
                            this.toggle('zipcode')
                        }, 500);

                    }}
                >

                    <Spring
                        from={{ opacity: 0 }}
                        to={{ opacity: countries ? 1 : 0 }}>
                        {props => <div style={props} className="countrySelection d-flex justify-content-center">
                            <Field
                                name="country"
                                component="input"
                                type="radio"
                                id="de"
                                value="105"
                                validate={required}
                            />
                            <label className="country m-2 p-2 bd-highlight" onClick={() => {
                                sleep(300).then(() => {
                                    document.getElementById("next").click();
                                });
                            }} htmlFor="de">Deutschland</label>

                            <Field
                                name="country"
                                component="input"
                                type="radio"
                                id="at"
                                value="106"
                                validate={required}
                            />
                            <label className="country m-2 p-2 bd-highlight" onClick={() => {
                                sleep(300).then(() => {
                                    document.getElementById("next").click();
                                });
                            }} htmlFor="at">Österreich</label>


                            <Field
                                name="country"
                                component="input"
                                type="radio"
                                id="ch"
                                value="107"
                                validate={required}
                            />
                            <label className="country m-2 p-2 bd-highlight" onClick={() => {
                                sleep(300).then(() => {
                                    document.getElementById("next").click();
                                });
                            }} htmlFor="ch">Schweiz</label>


                        </div>}
                    </Spring>

                    <button type="submit" id="next" style={{position: 'absolute'}}
                            className="btn btn-primary btn-block btn-lg register-form-btn register-form-combine invisible loadButton">
                        Weiter
                    </button>
                </MasterForm.Page>
                <MasterForm.Page
                    validate={values => {
                        let errors = {};
                        let countryID = this.registerFormRef.current.state.values.country;

                        if (!values.zipcode) {
                            errors.zipcode = 'Bitte gib deine Postleitzahl ein';
                        }

                        if (Object.keys(errors).length > 0) {

                            this.setState({
                                isValidating: false
                            });

                            return errors;
                        }
                        this.setState({
                            isValidating: true
                        });
                        return new Promise(resolve => {
                            this.API.checkZipCode(countryID, values.zipcode).then((data) => {
                                resolve(true);
                            }).catch((error) => {
                                errors.zipcode = error;
                                resolve(false);
                            });

                        }).then(() => {
                            this.setState({
                                isValidating: false
                            });
                            if (Object.keys(errors).length > 0) {
                                throw errors;
                            } else {
                                window.scrollTo(0, 0);
                                this.texts.push(`Ok das wird immer interessanter 😉️ Wie alt bist du eigentlich?`);

                                setTimeout(() => {
                                    this.setState(addItem);
                                    count++;
                                    this.scrollToBottom();
                                }, 400);
                            }
                        })
                    }}

                >

                    <Spring
                        from={{ opacity: 0 }}
                        to={{ opacity: zipcode ? 1 : 0 }}>
                        {props => <div style={props}  className="sendButtonWrapper">

                        <div className='form-group' style={{width: '75%'}}>
                            <Field
                                autoFocus={true}
                                name="zipcode"
                                component="input"
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="Wie ist deine Postleitzahl?"
                                validate={required}
                            />
                            <ErrorMessage
                                name="zipcode"
                                component="div"
                                className="field-error"
                            />
                        </div>

                        <div className="form-group">
                            <button type="submit" disabled={isValidating} className="btn btn-xs btn-primary loadButton btn-lg register-form-btn register-form-combine ">
                                <img src={SendIcon} height={20} width={20} alt=""/>
                            </button>
                        </div>
                    </div>}
                    </Spring>
                </MasterForm.Page>
                <MasterForm.Page
                    validate={values => {

                        function calculate_age(dob) {
                            var diff_ms = Date.now() - dob.getTime();
                            var age_dt = new Date(diff_ms);

                            return Math.abs(age_dt.getUTCFullYear() - 1970);
                        }

                        const errors = {};
                        if (!values.birthdate) {
                            errors.birthdate = 'Bitte gib dein Geburtsdatum an';
                        } else {
                            var age = calculate_age(new Date(values.birthdate));
                            if (age < 18) {
                                errors.birthdate = 'Du musst mindestens 18 Jahre alt sein';
                            }

                            if (age > 99) {
                                errors.birthdate = 'Du hast angegeben das du über 99 Jahre alt bist. Überprüfe deine Eingabe!';
                            }

                            if (Object.keys(errors).length === 0) {
                                window.scrollTo(0, 0);
                                this.texts.push(`${age} ist perfekt️ 🥰 Welchen Benutzernamen möchtest du denn?`);
                                setTimeout(() => {
                                    this.setState(addItem);
                                    count++;
                                    this.scrollToBottom();
                                }, 400);
                            }
                        }

                        return errors;
                    }}
                >
                    <div className="sendButtonWrapper">
                        <div className='form-group' style={{width: '75%'}}>
                            <Field
                                autoFocus={true}
                                name="birthdate"
                                component="input"
                                placeholder="Wie ist dein Geburtsdatum?"
                                type="date"
                                className="form-control form-control-lg"
                                validate={required}
                            />
                            <ErrorMessage
                                name="birthdate"
                                component="div"
                                className="field-error"
                            />
                        </div>

                        <div className="form-group">
                            <button type="submit" className="btn btn-xs btn-primary loadButton btn-lg register-form-btn register-form-combine ">
                                <img src={SendIcon} height={20} width={20} alt=""/>
                            </button>
                        </div>
                    </div>
                </MasterForm.Page>
                <MasterForm.Page
                    validate={values => {
                        let errors = {};

                        if (!values.username) {
                            errors.username = 'Bitte gib einen Benutzernamen ein';
                        }

                        if (Object.keys(errors).length > 0) {
                            return errors;
                        }
                        this.setState({
                            isValidating: true
                        });
                        return new Promise(resolve => {
                            this.API.checkUsername(values.username).then((data) => {

                                if (Object.keys(errors).length === 0) {
                                    this.texts.push(`OK ${values.username} 🤗 Gib jetzt ein Passwort ein`);
                                    setTimeout(() => {
                                        this.setState(addItem);
                                        count++;

                                        this.scrollToBottom();

                                    }, 400);
                                }

                                resolve(true);
                            }).catch((error) => {
                                errors.username = error;
                                resolve(false);
                            });

                        }).then(() => {
                            if (Object.keys(errors).length > 0) {
                                throw errors;
                            }
                        }).finally(() => {
                            this.setState({
                                isValidating: false
                            });
                        });
                    }}

                >

                    <div className="sendButtonWrapper">
                        <div className='form-group' style={{width: '75%'}}>
                            <Field
                                autoFocus={true}
                                name="username"
                                component="input"
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="Welchen Benutzernamen möchtest du?"
                                validate={required}
                            />
                            <ErrorMessage
                                name="username"
                                component="div"
                                className="field-error"
                            />
                        </div>

                        <div className="form-group">
                            <button type="submit" className="btn btn-xs btn-primary loadButton btn-lg register-form-btn register-form-combine ">
                                <img src={SendIcon} height={20} width={20} alt=""/>
                            </button>
                        </div>
                    </div>
                </MasterForm.Page>

                <MasterForm.Page
                    validate={values => {
                        let errors = {};

                        if (!values.password) {
                            errors.password = 'Bitte gib ein Passwort ein!';
                        }

                        if (Object.keys(errors).length > 0) {
                            return errors;
                        } else {
                            this.texts.push("Ok du hast es gleich geschafft! Nur noch 1 klick und wir können miteinander schreiben!");
                            setTimeout(() => {
                                this.setState(addItem);
                                count++;
                                this.scrollToBottom();
                            }, 400);
                        }
                    }}
                >
                    <div className="sendButtonWrapper">
                        <div className='form-group' style={{width: '75%'}}>
                            <Field
                                name="password"
                                component="input"
                                type="password"
                                className="form-control form-control-lg"
                                placeholder="Welches Passwort möchtest du?"
                                validate={required}
                            />
                            <ErrorMessage
                                name="password"
                                component="div"
                                className="field-error"
                            />
                        </div>

                        <div className="form-group">
                            <button type="submit" className="btn btn-xs btn-primary loadButton btn-lg register-form-btn register-form-combine ">
                                <img src={SendIcon} height={20} width={20} alt=""/>
                            </button>
                        </div>
                    </div>
                </MasterForm.Page>

                <MasterForm.Page
                    validate={values => {
                        let errors = {};

                        if (!values.email) {
                            errors.email = 'Bitte gib deine Emailadresse ein';
                        }

                        if (Object.keys(errors).length > 0) {
                            return errors;
                        }

                        this.setState({
                            isValidating: true
                        });
                        return new Promise(resolve => {
                            this.API.checkEmail(values.email).then((data) => {
                                resolve(true);
                            }).catch((error) => {
                                errors.email = error;
                                resolve(false);
                            });

                        }).then(() => {
                            if (Object.keys(errors).length > 0) {
                                throw errors;
                            }
                        }).finally(() => {

                            this.setState({
                                email: values.email
                            });

                            this.setState({
                                isValidating: false
                            });
                        });
                    }}
                >
                    <div className="sendButtonWrapper" style={{flexDirection: 'column', paddingLeft: 20, paddingRight: 20}}>
                        <div className='form-group'>
                            <Field
                                autoFocus={true}
                                name="email"
                                component="input"
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="Gib deine Email-Adresse ein"
                                validate={required}
                            />
                            <ErrorMessage
                                name="email"
                                component="div"
                                className="field-error"
                            />
                        </div>
                        <div className="form-group">
                            <button type="submit" disabled={isValidating}
                                    className="btn btn-primary btn-block btn-lg register-form-btn loadButton">
                                Kostenlos Registrieren
                            </button>
                        </div>
                    </div>
                </MasterForm.Page>
            </MasterForm>
        )
    }

    render() {

        return (
            <div>
                <div className="row loginContainer">
                    <div className="col-12 pl-0 pr-0">
                        <nav className="navbar navbar-light bg-dark pt-0 pb-0 ">
                            <img className="logo"
                                 src="./images/signup/logo.png"
                                 alt=""/>
                        </nav>
                        <div className="login">
                            <div className="bgC" />
                            <div className="pt-3" style={{justifyContent: 'center', display: 'flex'}}>


                                {
                                    (this.state.success) ?
                                        this._renderSuccess() : <div className="chat">
                                            <div className="chat-title">
                                                <h1>Alexandra</h1>
                                                <h2>online</h2>
                                                <figure className="avatar">
                                                    <img
                                                        src="./images/signup/review4.jpg"/>
                                                </figure>
                                            </div>

                                            <ul className="ul-c messages" id="chat-messages" ref={this.list}>
                                                <Transition
                                                    native
                                                    items={this.state.items}
                                                    keys={item => item.key}
                                                    from={{opacity: 0, transform: 'translate3d(0,60px,0)'}}
                                                    enter={{opacity: 1, transform: 'translate3d(0,0,0)'}}
                                                    leave={{opacity: 0}}
                                                    config={{tension: 500, friction: 25}}>
                                                    {({text, left, first, last}, i, state) => styles => {
                                                        return (
                                                            <animated.li
                                                                className="message"
                                                                ref={state === 'enter' && this.el}
                                                                style={styles}>
                                                                <figure className="avatar"><img
                                                                    src="./images/signup/review4.jpg"/>
                                                                </figure>
                                                                {text}
                                                            </animated.li>
                                                        )
                                                    }}
                                                </Transition>
                                            </ul>

                                            { this._renderForm() }
                                            <div  style={{textAlign: 'center', paddingBottom: 10, color: '#777777'}}>
                                                <small>Mit meiner Registrierung akzeptiere ich die <a target="_blank" rel="noopener noreferrer"
                                                                                                      href="https://flirtplan.de/agb.php">AGB</a> sowie
                                                    die <a
                                                        target="_blank" rel="noopener noreferrer"
                                                        href="https://flirtplan.de/datenschutz.php">Datenschutzrichtlinien</a></small>
                                            </div>
                                        </div>
                                }




                            </div>
                        </div>


                    </div>
                </div>

                <div className="row section">
                    <a href="#about" className="separate-bow">
                        <img className="logo"
                             src="./images/signup/heart.svg"
                             alt=""/>
                    </a>

                    <div className="container">
                        <h2>Was ist FlirtPaar</h2>
                        <h3>Verabredung zum Fickdate</h3>
                        <p>FlirtPaar ist die Lösung für alle, die in ihrem Sexleben Langeweile haben. Offenbar
                            besteht ein großes Bedürfnis nach vögeln außerhalb von Partnerschaft und Ehe. Während viele
                            Männer und Frauen eher zufällig in ein Fick-Abenteuer rutschen, gehen andere wählerisch und
                            mit Planung vor. Sie haben mit FlirtPaar das perfekte Portal gefunden, um Partner für
                            ein geiles Date zu treffen.</p>
                    </div>
                </div>

                <div className="row section">
                    <div className="container">
                        <ul className="circles">
                            <li>
                                <div className="circle">
                                    <img src="./images/signup/free.png"
                                         alt="Vorbereitung"/>
                                    <h3>Kostenlose Anmeldung</h3>
                                    <p>Die Anmeldung ist absolut kostenlos.</p>
                                </div>
                            </li>
                            <li>
                                <div className="circle">
                                    <img src="./images/signup/quality.png"
                                         alt="Ready to Date"/>
                                    <h3>FakeCheck</h3>
                                    <p>Jedes Profil wird bei uns händisch geprüft! Keine Chance für Fakes!</p>
                                </div>
                            </li>
                            <li>
                                <div className="circle">
                                    <img src="./images/signup/top.png"
                                         alt="Party"/>
                                    <h3>Flirtgarantie!</h3>
                                    <p>n jeder Stadt findest du hunderte Singles, die auf dich warten! Für jeden ist etwas dabei!</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="row section">
                    <div className="container">
                        <h2>Das sagen unsere Mitglieder</h2>
                        <h3>Erfolgsgeschichten (Namen und Foto geändert)</h3>
                        <ul className="people">
                            <li>
                                <div className="test">
                                    <img src="./images/signup/review8.jpg" alt=""/>
                                    <p>
                                        "Bestes Profil-Matching"
                                        <strong>mohel..lo</strong>
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div className="test">
                                    <img src="./images/signup/review1.jpg" alt=""/>
                                    <p>
                                        "So viel Auswahl, ich konnte meinen Augen nicht trauen! Eine hübscher als die andere! Wo habt ihr euch die ganzen Jahre versteckt?"
                                        <strong>Tom-aw</strong>
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div className="test">
                                    <img src="./images/signup/review2.jpg" alt=""/>
                                    <p>
                                        "Ich weiß nicht ob man sowas überhaupt erzählen darf, aber meinen jetzigen Verlobten habe ich tatsächlich hier kennengelernt! Was für ein super Service!"
                                        <strong>anyyjj</strong>
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div className="test">
                                    <img src="./images/signup/review6.jpg" alt=""/>
                                    <p>
                                        "Wahrscheinlich die beste und professionellste Partnervermittlung im Internet!"
                                        <strong>nnatt55</strong>
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <footer className="footer section imprint">
                    <div className="container">
                        <a target="_blank" rel="noopener noreferrer"
                           href="https://flirtplan.de/impressum.php">Impressum</a>
                    </div>
                </footer>

            </div>

        );
    }
}


export default RegisterPage;
